
.AboutPage {
    width: 100vw;
    height: 100vh;
    color: white;
    background-color: black;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
}

