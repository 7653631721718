.App {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.VStack.SU {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.HStack.SU {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.VStack.SU.mainContainer{
  overflow-x: hidden;
  overflow-y: scroll;
}

.larogsTopNav{
  height: 8vh;
  background-color:white;
  display: grid;
  place-items: center;
}

.HStack.SU.titleContainer{
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.largosTitle {
  font-size: 30px;
}


.mainContent{
  position: relative;
  width : 100%;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.secondary-content {
    position: relative;
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.AppInfoContianer {
    height: 100vh;
    width: 100vw;
}

.HStack-MainContent{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.AboutUsContainer{
  width: 60%;
  height: 100%;
  display: grid;
  place-items: center;
}

.AboutUsContainer .VStack.SU.aboutContentContainer{
  position: relative;
  width: 100%;
  height: auto;
}

.AboutUsContainer .VStack.SU.aboutContentContainer h2,
.AboutUsContainer .VStack.SU.aboutContentContainer h3,
.AboutUsContainer .VStack.SU.aboutContentContainer h4
{
  padding: 0px 40px 0px;
}

.AboutUsContainer .VStack.SU.aboutContentContainer h2 {
  font-size: 24px;
  color: rgb(0, 0, 0, 0.85);
}

.AboutUsContainer .VStack.SU.aboutContentContainer h3 {
  font-size: 20px;
  color: rgb(0, 0, 0, 0.75);
}

.AboutUsContainer .VStack.SU.aboutContentContainer h4 {
  font-size: 16px;
  color: rgb(0, 0, 0, 0.55);
}

.ContactUsContainer {
  width: 40%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: grid;
  place-items: center;
}


.ContactUsContainer .formContainer{
  color: white;
  width: 90%;
  height: auto;
  /* background-color: red; */
}

.ContactUsContainer .formContainer h2{
  font-size: 25;
  height: 40px;
  width: 95%;
  margin-left: 2.5%;
}
/* .ContactUsContainer .formContainer {} */
.ContactUsContainer .formContainer select{
  height: 40px;
  width: 95%;
  margin-left: 2.5%;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
}

.ContactUsContainer .formContainer .HStack.SU.InputTags{
  width: 95%;
  margin-left: 2.5%;
  margin-top: 20px;
  font-weight: 600;
}

.ContactUsContainer .formContainer .HStack.SU.InputTags input{
  margin-left: 20px;
  width: 85%;
  border-radius: 10px;
  padding-left: 15px;
}

.ContactUsContainer .formContainer textarea{
  height: 400px;
  width: 95%;
  /* margin-left: 2.5%; */
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  font-weight: 600;
  margin-top: 20px;
}

.ContactUsContainer .formContainer .ContactUsSubmitContainer{
  width: 100%;
  height: auto;
  display: grid;
  place-items: center;
}

.ContactUsContainer .formContainer .ContactUsSubmitContainer .SubmitButton {
  height: 40px;
  aspect-ratio: 3/4;
  border: none;
  background-color: white;
  color: black;
  font-weight: 700;
  margin-top: 20px;
  border-radius: 10px;
}

@media (max-width : 1024px) {
  .mainContent {
      position: relative;
      width: 100%;
      height: auto;
      background-color: white;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .HStack-MainContent {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
    }

    .AboutUsContainer {
      width: 100%;
      height: auto;
      display: grid;
      place-items: center;
      margin-top: 40px;
    }

    .AboutUsContainer .VStack.SU.aboutContentContainer h2 {
      font-size: 30px;
    }

    .ContactUsContainer {
      width: 100%;
      height: auto;
      background-color: rgb(0, 0, 0);
      overflow: hidden;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0px;
      margin-top: 100px;
    }
}