h1 {
    margin: 0;
}

.CreditsPageContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.backgroundImageContainer {
    z-index: 1;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.backgroundImageContainer .backgroundImage {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    opacity: 0.15;
}

.CreditsPage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    color: white;
    overflow-x: scroll;
    overflow-y: scroll;
    padding: 0;
    z-index: 100;
}

.Credits-larogs-Top-Nav {
    margin-top: 20px;
}

.CreditsPageTitle {
    font-size: 35px;
    margin-top: 12px;
}

.CreditsListContainer {
    position: relative;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: scroll;
    margin: 0;
    padding: 0;
}

.CreditsListContainer {
    margin: 0;
    padding: 20px;
    height: auto;
    align-items: center;
}

.CreditsListContainer li {
    list-style: none;
    margin-top: 12px;
    text-align: center;
}

.CreditsListContainer li a {
    text-decoration: none;
}

.CreditsTitle {
    font-size: 24px;
    margin-top: 16px;
    margin-bottom: 12px;
    text-align: center;
}

.creditsLinksA {
    color: white;
}

.creditsAppLogo {
    border-radius: 12px;
    margin-top: 20px;
    height: 60px;
    width: 60px;
}